<template>
<div>
    <h1 class="text-center mb-10 page_title">アクセス</h1>

    <div class="mx-auto googlemap">
        <p class="ml-3 text-left"><b>ベイサイド104（旧肥中海水浴･オートキャンプ場）</b><br>〒759-5331 山口県下関市豊北町大字神田肥中<br>電話番号：083-786-0458</p>
        <div class="text-center flex-center mt-16 pt-16" v-if="!set">
            <div>
                <p>Loading...</p>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
        </div>
        <iframe @load="set=true" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d823.6841548402158!2d130.8947495300594!3d34.33172631998915!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa769283f3dabea7c!2z44OZ44Kk44K144Kk44OJMTA077yI6IKl5Lit5rW35rC05rW05aC044O744Kq44O844OI44Kt44Oj44Oz44OX5aC077yJ!5e0!3m2!1sja!2sjp!4v1622809877800!5m2!1sja!2sjp" class="w-100" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            set: false
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/access.scss";
</style>
